














import { defineComponent, watch, ref, onMounted } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import { ZidPageHeader } from '@zidsa/ui';
import { PagesNamesEnum } from '@/router/pages.names.enum';
import store from '@/store';
export default defineComponent({
  components: {
    BaseText,
    ZidPageHeader,
  },
  setup(_, ctx) {
    const pageName = ref(ctx.root.$route.params.pageName);

    watch(
      () => ctx.root.$route.params,
      (newParams) => {
        pageName.value = newParams.pageName;
      },
      { deep: true },
    );
    onMounted(() => {
      store.dispatch('setIsAppLoading', false);
    });

    return { pageName, PagesNamesEnum };
  },
});
